.contact-us-container {
  /* margin-top: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/location.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin-top: 10px;

  /* Glass effect */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Adjust the alpha channel as needed */
  /* border-radius: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Adjust the box-shadow as needed */
}

.map-contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
  width: 100%;
  align-items: center;
  max-width: 1800px;
  margin: 1rem auto;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* background-color: #fff; */
  border-radius: 20px;
  height: 100%;
}

iframe {
  flex-basis: 100%;
  margin: 1rem;
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  border: black 2px solid;
  /* height: auto; */
}
.dark-theme iframe {
  filter: grayscale(20%) invert(92%) contrast(83%) sepia(10%) hue-rotate(180deg)
    saturate(120%);
}
.dark-theme .contact-container {
  background-color: black;
}
.contact-container {
  display: flex;
  flex-basis: 100%;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  font-size: large;
  background-color: ivory;
  border-radius: 20px;
  max-width: 500px;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

li {
  padding: 0.5rem;
}

/* Media Queries */
@media only screen and (min-width: 768px) {
  .map-contact-container {
    flex-direction: row;
    /* margin-top: 100px; */
  }

  iframe {
    flex-basis: 50%;
  }

  .contact-container {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 1181px) {
  .contact-us-container {
    height: 100%;
  }
}
