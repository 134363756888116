/* Services.css */

.services-container {
  margin-top: -10px;
}

.sap-service-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive grid columns */
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 1rem auto;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.services-image {
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.sap-service-item {
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
}

.sap-service-item:hover,
.grid-item:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.application-development-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.grid-item {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-height: fit-content;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.sap-image,
.application-image,
.mobile-application-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: block;
  border-radius: 8px; /* Optional: Add border-radius to the image */
}

.enterprise-image,
.cloud-based-image,
.android-image,
.apple-image,
.cross-image,
.ecom-image-1,
.ecom-image-2,
.data-science-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  display: block;
  border-radius: 8px; /* Optional: Add border-radius to the image */
}

.benefits-list {
  text-align: center;
}

.link-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.link-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: #000;
  padding: 0.5rem;
  border-radius: 10px;
  /* width: 100%; */
  text-align: center;
  background-color: #ff9900;
}
.link-button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Responsive layout for smaller screens */
@media screen and (max-width: 600px) {
  .sap-service-container,
  .application-development-container {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
  .grid-item {
    flex-direction: column;
  }
}
