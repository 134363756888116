/* Basic styling for the AboutUs component */
.about-us-container {
  max-width: 100vw;
  margin: 0 auto;
  /* padding: 10px 0; */
  text-align: justify;
  display: flex;
  flex-direction: column; /* Set default direction to row */
  justify-content: space-between; /* Add space between content and image */
  align-items: center; /* Align items vertically */
  opacity: 0;
  animation: fadeInAnimation 1s ease-in-out forwards;
  margin-top: -42px;
}

/* Sections within the AboutUs component */
.our-strength-section,
.what-we-do-section,
.how-are-we-different-section {
  display: flex; /* Allow content to take available space */

  padding: 1rem;
  gap: 2rem;
  max-width: 1000px;
  border-radius: 2%;
  margin: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.grid-container {
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Updated to 2 columns */
  grid-template-rows: repeat(2, 1fr); /* Updated to 2 rows */
  gap: 20px;
  margin: 20px;
  padding: 1rem;
}

.about-us-grid-item {
  height: auto;
  /* overflow: hidden; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.grid-item:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.top {
  max-height: 200px;
  overflow: hidden;
  flex-basis: 50%;
}

.bottom {
  padding: 15px;
  flex-basis: 50%;
  /* background-color: #f9f9f9; */
}

.technology-stack-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  max-width: 1000px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.stack-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #ccc; */
  padding: 10px;
}

.stack-item img {
  max-width: 100%;
  max-height: 100%;
  width: 80px; /* Adjust the width to your preference */
  height: auto;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
h3 {
  text-align: center;
}

.service-value-section {
  max-width: 1000px;
  margin: 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 1rem;
}
.list-content {
  text-align: center;
  max-width: 400px;
  margin: auto;
}
/* Image styles */
.about-us-image {
  width: 100%;
  margin-top: 30px;
}

p {
  text-align: justify;
}

/* Styles for the animated image */
.animated-image-container {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: slideInAnimation 1s ease-in-out forwards;
}

.animated-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  transition: transform 0.7s ease-in-out;
  /* flex-basis: 50%; */
}

.animated-image:hover {
  transform: scale(1.1);
}
.about-us-engagement-images {
  width: 100%;
  max-width: 500px;
  height: auto;
  flex-basis: 50%;
}
/* Header and paragraph styles */

p {
  color: #fff;
  line-height: 1rem;
  padding: 0.5rem;
}

h2 {
  text-align: center;
  padding: 0.5rem;
}

/* Custom fadeIn animation */
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Custom slideIn animation */
@keyframes slideInAnimation {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Media queries for responsiveness */
@media only screen and (max-width: 1000px) {
  .animated-image {
    height: 300px;
  }
}

@media only screen and (max-width: 850px) {
  .animated-image-container {
    margin: 20px auto;
  }

  .our-strength-section,
  .what-we-do-section,
  .how-are-we-different-section {
    display: flex; /* Allow content to take available space */
    padding: 1rem;
    flex-direction: column;
    margin: 1rem;
  }

  .grid-item {
    flex-direction: column;
  }
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .technology-stack-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
