/* Training.css */

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../assets/austin-kehmeier-lyiKExA4zQA-unsplash.jpg);
  /* margin-top: 160px; */
}

.joinUsImage {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 100px;
  position: relative;
  overflow: hidden;
}

.form:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 5px solid transparent;
  border-image-source: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-image-slice: 1;
  border-radius: 10px;
  z-index: 2;
  animation: animatedgradient 3s ease alternate infinite;
}

textarea {
  height: 100px;
  width: 100%;
  resize: vertical;
  margin: 1rem;
  padding: 1rem;
  border-radius: 15px;
  z-index: 10;
}

textarea:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.submit-button {
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  font-weight: bolder;
  font-size: medium;
  border: none;
  border-radius: 10px;
  letter-spacing: 4px;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
  letter-spacing: 0.5px;
  background-color: #ff9900;
}

.submit-button:hover {
  background: #fff;
  color: #050801;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.submit-button:active {
  box-shadow: 0 0 5px #ff9900, 0 0 25px #ff9900, 0 0 50px #ff9900,
    0 0 200px #ff9900;
}

input {
  height: 3rem;
  width: 100%;
  font-size: medium;
  padding: 1rem;
  margin: 1rem;
  border-radius: 15px;
  z-index: 10;
}

.join-us-text {
  animation: breathe 5s infinite;
}

label {
  font-size: larger;
  font-weight: bold;
}

input:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

li {
  list-style: none;
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animatedgradient {
  0% {
    border-image-source: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ff0000,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
  }
  15% {
    border-image-source: linear-gradient(
      60deg,
      #6fba82,
      #f79533,
      #f37055,
      #ff0000,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b
    );
  }
  30% {
    border-image-source: linear-gradient(
      60deg,
      #f37055,
      #ff0000,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82,
      #f79533
    );
  }
  45% {
    border-image-source: linear-gradient(
      60deg,
      #1098ad,
      #07b39b,
      #6fba82,
      #f79533,
      #f37055,
      #ff0000,
      #a166ab,
      #5073b8
    );
  }
  60% {
    border-image-source: linear-gradient(
      60deg,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82,
      #f79533,
      #f37055,
      #ff0000,
      #a166ab
    );
  }
  75% {
    border-image-source: linear-gradient(
      60deg,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82,
      #f79533,
      #f37055,
      #ff0000
    );
  }
  90% {
    border-image-source: linear-gradient(
      60deg,
      #07b39b,
      #6fba82,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad
    );
  }
  100% {
    border-image-source: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
  }
}

/* Responsive Styles */

@media only screen and (min-width: 600px) {
  .form-container {
    /* flex-direction: row; */
  }
}

@media only screen and (min-width: 768px) {
  /* Add additional styles for larger screens if needed */
}
