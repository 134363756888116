/* src/App.css */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Raleway", sans-serif;
}
body {
  color: #fff !important;
  padding-top: 60px;
  /* font-family: "Arial", sans-serif; */
  margin: 0;
  padding: 0;
  background-color: #000; /* Light background color */
  min-width: 280px;
  background-image: url("./assets/mainbackground.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Optional: Fixed background for better effect */
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
  margin: 0.2rem 0;
  padding: 0.2rem;
  line-height: 2rem;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--glass-background);
  backdrop-filter: blur(10px); /* Glass effect */
  z-index: -1; /* Place the pseudo-element behind the content */
}

.theme-toggle-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: #ff9900;
  padding: 0.5rem;
  border-radius: 10px;
}
.theme-toggle-button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hamburger-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: #ff9900;
  display: none; /* Hide the hamburger button by default for larger screens */
  margin: 1rem;
}

.light-theme body,
.light-theme li,
.light-theme p,
.light-theme h1,
.light-theme h2,
.light-theme h3,
.light-theme h4,
.light-theme h5,
.light-theme h6,
.light-theme label {
  color: #000 !important;
}

/* Add other light theme styles as needed */

/* Dark Theme */
.dark-theme body,
.dark-theme li,
.dark-theme p,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme label {
  color: #fff !important;
}

.light-theme {
  --glass-background: rgba(255, 255, 255, 1);
}

.dark-theme {
  --glass-background: rgba(0, 0, 0, 0.9);
}
.dark-theme nav {
  background-color: #000;
}
.dark-theme nav a {
  color: white;
}

.logo {
  width: 160px;
  height: 60px; /* Adjust the height as needed */

  justify-self: flex-start;
  transition: transform 0.5s ease; /* Add a smooth transition on hover */
}

nav {
  background-color: #fff; /* Glass background color with transparency */
  font-size: 2rem;
  /* padding: 3rem; */
  text-align: center;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: background-color 0.5s ease;
  top: 0;
  backdrop-filter: blur(10px); /* Add the glass effect with backdrop-filter */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Updated styles for logo, company name, and links */
.logo-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 15%;
}

.company-name {
  flex-basis: 30%;
  font-size: large;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  color: #001a1a;
}

nav a {
  margin: 0.2rem 0.4rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  text-decoration: none;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  transition: background-color 0.5s ease, transform 0.5s ease; /* Add a smooth transition on hover */
}

nav a:hover {
  background-color: #ff9900;
  transform: scale(1.1);
  /* border: 3px solid #0018ad; */
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

nav a.active {
  font-weight: bold;
  background-color: #ff9900; /* Highlight color for the active link */
  color: #fff; /* Text color for the active link */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* border: 3px solid #0018ad; */
}

main {
  transition: margin-top 0.5s ease, opacity 0.5s ease; /* Add transitions for margin and opacity */
  opacity: 1;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #ff9900;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ff9900;
}

::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
}

::-webkit-scrollbar-corner {
  background-color: #fff;
}

/* Responsive styles using media queries */

/* Small screens */
@media (max-width: 600px) {
  nav {
    padding: 1rem;
  }

  nav a {
    display: block;
    width: 100%;
    margin: 10px 2px;
    font-size: 0.65rem;
  }

  main {
    margin-top: 80px;
  }
}

/* Medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
  nav {
    padding: 0.5rem;
  }
  nav a {
    /* margin: 1px; */
    /* padding: 8px 12px; */
    font-size: 0.8rem;
  }
  main {
    margin-top: 80px;
  }
}

/* Large screens */
@media (min-width: 1025px) {
  nav {
    padding: 1rem;
  }
  nav a {
    margin: 0.5rem;
    /* padding: 8px 16px; */
    /* font-size: 0.7rem; */
  }
  main {
    margin-top: 80px;
  }
}
@media screen and (max-width: 800px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #fff;
    width: 100%;
    display: none;
  }

  .nav-links.show {
    display: flex;
  }
  .dark-theme nav a {
    color: #000;
  }

  .hamburger-button {
    display: block; /* Show the hamburger button on smaller screens */
  }
}
