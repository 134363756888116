.OurTeamContainer {
  /* background-color: #f5f5f5; */
  margin-top: -10px;
}

.content-container {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.our-team-image {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.images-container {
  max-width: 800px;
  margin: 1rem auto;
}
.office-image {
  width: 100%;
  /* max-width: 1200px; */
  height: auto;
  padding: 2rem;
  justify-self: center;
  align-self: center;
}
.our-team-grid-container {
  display: grid;
  grid-template-areas:
    ". a a ."
    "b b b b"
    "c d e f";
  margin: auto;
  padding: 1rem;
  gap: 1rem;

  justify-content: center;
  align-items: center;
  max-width: 1400px;
}

.our-team-grid-heading {
  grid-area: a;
  padding: 1rem;
  animation: breathe 5s infinite;
}
.our-team-grid-para {
  grid-area: b;
  padding: 1rem;
}

.certificate-container {
  display: flex;
  flex-direction: column;
  grid-area: c;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}
.freshers-container {
  display: flex;
  flex-direction: column;
  grid-area: d;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}
.employee-container {
  display: flex;
  flex-direction: column;
  grid-area: e;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}
.extramile-container {
  display: flex;
  flex-direction: column;
  grid-area: f;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}
.certificate-image,
.freshers-image,
.employee-image,
.extramile-image {
  width: 100%;
  max-width: 200px;
  height: auto;
}

h2 {
  font-size: 24px;
  color: #333;
}
@keyframes breathe {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
/* Responsive Styles */
@media only screen and (max-width: 1400px) {
  .our-team-grid-container {
    grid-template-areas: "a a" " b b" " c d" " e f";
  }
}
@media only screen and (max-width: 768px) {
  .content-container {
    padding: 0 20px;
  }
  .our-team-grid-container {
    grid-template-areas: "a " " b " " c" "d" " e " "f";
  }

  h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .our-team-image {
    max-width: 100%;
  }
}
