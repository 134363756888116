h3 {
  text-align: center;
}
.carousel-container {
  max-width: 900px;
  margin: 0 auto;
}
.carousel-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 400px;
  border-radius: 5px;
  /* margin-top: -100px; */
  margin: auto;
}

.home-container {
  max-width: 1400px;
  margin: 1rem auto;
  padding: 0.2rem;
  color: #fff;
  background-blend-mode: lighten;
}

.homeImage {
  width: 100%; /* Make the image responsive */
  max-width: 500px;
  height: auto; /* Maintain aspect ratio */
}

.our-premium-clients {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.client-images {
  width: 100%; /* Make the image responsive */
  max-width: 200px;
  height: auto; /* Maintain aspect ratio */
}

.content-image-container {
  /* background-color: #fff; */

  width: 100%;
  max-width: 1000px;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin: 2rem auto;
  gap: 1rem;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  transition: box-shadow 0.3s ease-in-out;
}

.content-image-container:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 30px,
    rgba(0, 0, 0, 0.1) 0px -10px 20px, rgba(0, 0, 0, 0.1) 0px 4px 6px,
    rgba(0, 0, 0, 0.15) 0px 10px 10px, rgba(0, 0, 0, 0.05) 0px -2px 4px;
}

h1 {
  text-align: center;
  margin: 2rem 0;
}

.intro-text,
.secondary-text,
.contact-text {
  font-size: 16px;
  margin-bottom: 15px;
}

/* Apply different styles for larger screens */
@media only screen and (min-width: 900px) {
  .content-image-container {
    flex-direction: row;
  }

  .homeImage {
    flex-shrink: 0; /* Prevent image from shrinking */
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }
  .our-premium-clients {
    flex-direction: column;
  }
  .client-images {
    margin: 1rem 0;
  }
}

/* Apply different styles for smaller screens */
@media only screen and (max-width: 900px) {
  .content-image-container {
    flex-direction: column;
  }
}

/* Apply different styles for even smaller screens */
@media only screen and (max-width: 480px) {
  .home-container {
    padding: 1rem;
  }
}
@media (max-width: 600px) {
  .technology-stack-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Media query for screens between 601px and 900px */
@media (min-width: 601px) and (max-width: 900px) {
  .technology-stack-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Media query for screens between 901px and 1200px */
@media (min-width: 901px) and (max-width: 1200px) {
  .technology-stack-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Media query for screens larger than 1200px */
@media (min-width: 1201px) {
  .technology-stack-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
